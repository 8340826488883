import React from "react"

import Image from "./assets/Image"

import styles from "./styles.module.scss"

export default {
	mediaContent: <Image className={styles.image} />,
	heading: (
		<>
			Страница не найдена,
			<br />
			но мы не сдаёмся
		</>
	),
	btn: {
		text: "Перейти на главную",
		link: "/",
		useGatsbyLink: true,
	},
}
