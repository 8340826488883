import React from "react"
import classNames from "classnames"

import DefaultLayout from "../layout/default"

import Error404 from "../pagesSections/404"

import pageStyles from "../styles/_V2/pagesStyles/styles.module.scss"

import Image404 from "../pagesSections/404/assets/404.png"

export default function Page404() {
	const title = "Ошибка 404: страница не найдена | Quick Resto"
	const description =
		"Quick Resto – автоматизация ресторана, кафе, бара, кофейни или столовой под ключ. Программа для полноценного складского учёта в общепите онлайн, удобный кассовый терминал на планшете, CRM и настраиваемая система отчетов. Перейдите на главную страницу, чтобы оставить заявку на подключение."
	const metaTags = [
		{
			name: "description",
			content: description,
		},
		{
			property: "og:title",
			content: title,
		},
		{
			property: "og:description",
			content: description,
		},
		{
			property: "og:type",
			content: "website",
		},
		{
			property: "og:image",
			content: Image404,
		},
	]

	return (
		<DefaultLayout
			title={title}
			metaTags={metaTags}
			mainWrapperClassName={classNames(
				pageStyles.pageWrapper,
				pageStyles.pageGray
			)}
		>
			<Error404 />
		</DefaultLayout>
	)
}
