import React from "react"
import classNames from "classnames"

import AdaptiveLink from "../../components/_V2/AdaptiveLink"

import styles from "./styles.module.scss"

import data from "./data"

interface Props {
	className?: string // Класс
}

export default function Error404(props: Props) {
	return (
		<section className={classNames(styles.section, props.className)}>
			{data.mediaContent}

			<h2 className={styles.heading}>{data.heading}</h2>

			<AdaptiveLink href={data.btn.link} useGatsbyLink={data.btn.useGatsbyLink} className={styles.btn}>
				<span>{data.btn.text}</span>
			</AdaptiveLink>
		</section>
	)
}
